<template>
  <div class="box">
    <div class="codeState" v-if="status == 99">
      <img src="../../assets/codeState/invalidActivate.jpg" alt="" />
    </div>
    <div v-else-if="status == 1">
      <div class="codeState">
        <img src="../../assets/codeState/weijihuo.png" alt="" />
        <!-- <div class="codeJudge">
        <p class="codeTitle">码状态</p>
        <div class="codeBorder" v-if="factory == 0">正确数据</div>
        <div class="codeBorder" v-else-if="factory == 1">数据已关联</div>
        <div class="codeBorder" v-else-if="factory == 2">数据已激活</div>
        <div class="codeBorder" v-else>错误数据</div>
      </div> -->
      </div>
      <ul class="codeDetails">
        <li>
          <p>码包名称：</p>
          <span>{{ packet }}</span>
        </li>
        <li>
          <p>打码工厂：</p>
          <span>{{ factory }}</span>
        </li>
        <li>
          <p>SKU：</p>
          <span>{{ sku }}</span>
        </li>
        <li>
          <p>生成时间：</p>
          <span>{{ packageCreatTime }}</span>
        </li>
      </ul>
    </div>
    <div class="jszc">
      <img src="../../assets/jszc.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'codeState',
  data() {
    return {
      factory: '',
      sku: '',
      packageCreatTime: '',
      packet: '',
      status: ''
    }
  },
  methods: {},
  mounted: function () {
    this.factory = this.$route.query.factory
    this.sku = this.$route.query.sku
    this.packageCreatTime = this.$route.query.packageCreatTime
    this.packet = this.$route.query.packet
    this.status = this.$route.query.status
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.codeState {
  display: block;
  width: 100%;
  position: relative;
}
.codeState img {
  width: 100%;
}
.codeBorder {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  border-radius: 60px;
  border: 1px solid #fff;
  width: 400px;
  line-height: 80px;
  color: #fff;
  margin: 60px auto 0;
}
body {
  background: rgba(0, 0, 0, 0);
}
.codeJudge {
  position: absolute;
  top: 20px;
  width: 100%;
}
.codeTitle {
  color: #fff;
  font-size: 28px;
  padding: 30px 0 0 30px;
}
.codeDetails {
  font-size: 26px;
  li {
    padding: 30px;
    display: flex;
    p {
      padding-right: 20px;
    }
    span {
      color: #666;
    }
  }
}
.jszc {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  img {
    width: 100%;
  }
}
</style>
